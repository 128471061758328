<template>
    <div class="view pa24 rentalManagementTable">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
                <!-- <el-button type="primary">导出数据</el-button> -->
            </div>
            <div style="margin-left: auto">
                <el-button class="ma mr10" type="primary" @click="showAddModel">设置出租房屋</el-button>
                <!-- <el-button class="ma" type="primary" @click="showAddModel">导入房号</el-button> -->
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="hireImg" align="center" label="图片展示" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.hireImg" fit="fit"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="hireTitle" align="center" label="出租标题" show-overflow-tooltip />
                <el-table-column prop="hirePrice" align="center" label="出租价格（元/月）" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.hirePrice }}元
                    </template>
                </el-table-column>
                <el-table-column prop="houseName" align="center" label="房屋/单元" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.houseName }}/{{ scope.row.number }}单元
                    </template>
                </el-table-column>
                <el-table-column prop="houseArea" align="center" label="房屋面积" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.houseArea }}m²
                    </template>
                </el-table-column>
                <el-table-column prop="carArea" align="center" label="车位面积" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.carArea }}m²
                    </template>
                </el-table-column>
                <el-table-column prop="hireStatusName" align="center" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag>{{ scope.row.hireStatusName }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="130">
                    <template slot-scope="scope">
                        <el-button class="mr10" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <!-- <el-button class="mr10" style="color:#F56C6C" type="text" @click="showUpdateModel(scope.row)">删除
                        </el-button> -->
                    </template>
                </el-table-column>
            </template>
        </commonTable>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="50%" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="120px">
                <el-form-item label="房屋" prop="houseId">
                    <el-col :md="9">
                        <el-select class="w100p" v-model="formData.houseId" filterable remote
                            :remote-method="getHouseManageList" :loading="loadingBuild" placeholder="请选择房屋">
                            <el-option v-for="(row, index) of houseData" :key="index" :value="row.houseId"
                                :label="row.houseName"></el-option>
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="展示图片" prop="hireImg">
                    <el-upload :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
                        :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="hireImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="h100p d-flex align-items-center">
                        <p>最多只能上传1张，建议上传750x750，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="出租标题" prop="hireTitle">
                            <el-input v-model="formData.hireTitle" placeholder="请输入出租标题"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="住金" prop="hirePrice">
                            <el-input class="suffixInput" v-model="formData.hirePrice" placeholder="请输入住金"
                                type="number">
                                <template slot="append">元/月</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="出租规则" prop="remark">
                            <el-select class="w100p" v-model="formData.remark" filterable placeholder="请选择出租规则">
                                <el-option v-for="(row, index) of rentalRulesData" :key="index" :value="row.dictLabel"
                                    :label="row.dictLabel"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="状态" prop="hireStatusData">
                            <el-select class="w100p" v-model="formData.hireStatus">
                                <el-option v-for="(name, id) of hireStatusData" :key="id" :label="name"
                                    :value="Number(id)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="标识" prop="hireKey">
                    <el-input class="input-new-tag w70" v-if="inputVisible" v-model="tag" ref="saveTagInput"
                        size="small" @keyup.enter.native="addTagFun" @blur="addTagFun">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 标识</el-button>
                    <el-tag size="medium" :key="tag" v-for="tag in hireKey" closable :disable-transitions="false"
                        @close="closeTagFun(tag)">
                        {{ tag }}
                    </el-tag>
                </el-form-item>
                <el-form-item label="房屋介绍" prop="hireContent">
                    <quill-editor style="height: 100%" v-model="formData.hireContent" ref="myQuillEditor"
                        :options="editorOption" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
/**富文本配置 */
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写房屋介绍'
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

import commonTable from "@/components/common/commonTable";
import { getHouseManageList, getHouseHireList, setHouseHire, getByDictType } from "@/api/propertyServices";
export default {
    name: "houseTable",
    components: {
        commonTable,
        quillEditor
    },
    data() {
        return {
            editorOption: quillConfig,
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            uploadDisabled: false,
            rentalRulesData: [],//出租规则数据
            formData: {
                hireImg: "",//图片
                hirePrice: "",//出租价格
                hireTitle: "",//标题
                houseId: "",//房屋id
                hireContent: "",//内容
                hireKey: "",//	关键词
                hireStatus: 0,//出租状态（0：待出租，1：已出租，2：取消)
                remark: "",
            },
            hireImg: [],//图片
            tag: "",
            inputVisible: false,
            hireKey: [],//标识
            rules: {
                houseId: {
                    required: true,
                    trigger: "change",
                    message: "请输入房屋",
                },
                hireImg: {
                    required: true,
                    message: "请输入上传图片",
                },
                hireTitle: {
                    required: true,
                    trigger: "blur",
                    message: "请输入出租标题",
                },
                hirePrice: {
                    required: true,
                    trigger: "blur",
                    message: "请输入出租价格",
                },
                remark: {
                    required: true,
                    trigger: "change",
                    message: "请选择出租规则",
                },

            },
            formType: "",
            updateId: 0,
            houseData: {},
            hireStatusData: {
                0: "待出租",
                1: "已出租",
                2: "取消"
            },
        };
    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark
        }
    },
    watch: {
        async watchCheckedPark(row) {
            this.parkId = row.parkId;
            this.currentPage = 1
            await this.getHouseManageList();
            this.getList();
        }
    },
    async created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        await this.getHouseManageList();
        this.getList();
        this.getByDictType();
    },
    methods: {
        getByDictType() {
            let that = this;
            getByDictType({ dictType: "rental_rules", status: 0 }).then(rentalRulesRes => {
                try {
                    that.rentalRulesData = rentalRulesRes.data.sysDictDataList
                } catch (err) {
                    that.rentalRulesData = [];
                }
            })
        },
        /**@method 显示标识输入框 */
        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            })
        },
        /**@method 删除标识  */
        closeTagFun(tag) {
            this.hireKey.splice(this.hireKey.indexOf(tag), 1)
        },
        /**@method 添加标识 */
        addTagFun() {
            let tag = this.tag;
            if (tag && this.hireKey.indexOf(tag) == -1) {
                this.hireKey.push(tag);
            }
            this.inputVisible = false;
            this.tag = "";
        },
        /**@method 获取房屋数据 */
        async getHouseManageList(houseName) {
            this.loadingBuild = true;
            let res = await getHouseManageList({ parkId: this.parkId, pageNum: 1, pageSize: 30, houseName });
            this.loadingBuild = false;
            try {
                let houseData = {};
                for (let row of res.data.pageInfo.list) {
                    houseData[row.houseId] = row;
                }
                this.houseData = houseData
            } catch (err) {
                this.houseData = {};
            }
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1;
            this.getList();
        },
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate(async (valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        hireKey: this.hireKey.join(","),
                        parkId: this.parkId
                    };
                    if (!params.hireImg) {
                        this.$message({
                            message: "请上传展示图片",
                            type: "error"
                        })
                        return;
                    }
                    let message = "新增成功"
                    if (this.formType === 'update') {
                        message = "编辑成功"
                        params.hireId = this.updateId;
                    }
                    let res = await setHouseHire(params);
                    if (res.code === 200) {
                        this.$message({
                            message: message,
                            type: "success"
                        })
                        this.getList();
                        this.showModel = false;
                    }
                }
            });
        },
        /**@method 删除图片 */
        linkmanImgRemove(file, fileList) {
            this.formData.hireImg = "";
        },
        /**@method 图片上传 */
        linkmanImgProgress(event, file, fileList) {

        },
        /**@method 上传回调 */
        linkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.formData.hireImg = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                parkId: this.parkId,
                hireTitle: this.searchData.name,
            };
            try {
                this.loading = true;
                let res = await getHouseHireList(params);
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    row.hireStatusName = this.hireStatusData[row.hireStatus];
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增出租房屋";
            this.formType = "add";
            this.formData = {
                hireImg: "",//图片
                hirePrice: "",//出租价格
                hireTitle: "",//标题
                houseId: "",//房屋id
                hireContent: "",//内容
                hireKey: "",//	关键词
                hireStatus: "",//出租状态
                remark: ""
            };
            this.hireKey = [];
            this.hireImg = [];
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.modelTitle = "编辑出租房屋";
            this.formType = "update";
            this.updateId = row.hireId;
            this.getHouseManageList(row.houseName);
            this.formData = {
                hireImg: row.hireImg,//图片
                hirePrice: row.hirePrice,//出租价格
                hireTitle: row.hireTitle,//标题
                houseId: row.houseId,//房屋id
                hireContent: row.hireContent,//内容
                hireKey: row.hireKey,//	关键词
                hireStatus: row.hireStatus,//出租状态
                remark: row.remark,
            };
            this.hireImg = [{ url: row.hireImg }]
            if (row.hireKey) {
                this.hireKey = row.hireKey.split(",")
            }
        },
    },
};
</script>

<style>
.rentalManagementTable .el-tag {
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    margin-left: 10px;
}

.rentalManagementTable .input-new-tag {
    width: 90px;
    vertical-align: bottom;
}
</style>
<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>